<template>
  <div
    id="newsletter"
    ref="newsletter"
    class="newsletter relative mt-20 bg-neutral-800 px-12 py-6">
    <div class="absolute left-0 top-0 -translate-y-1/2">
      <NuxtImg
        src="/ondes_desktop.png"
        fit="contain"
        class="hidden w-fit shrink-0 object-contain dt:block" />
      <NuxtImg
        src="/ondes_mobile.png"
        fit="contain"
        class="shrink-0 object-contain dt:hidden" />
    </div>
    <!-- hidden newsletter -->
    <!--   <div class="mx-auto max-w-md pt-14">

      <div class="mb-3 text-sm text-neutral-700">
        {{ $t('NewsletterSection.uptitle') }}
      </div>

      <form v-if="!sent" @submit.prevent="send">
        <ErrorList :errors="errors" />
        <h3 v-html="$t('NewsletterSection.title')" class="mb-2 text-left text-lg text-neutral-400" />

        <FormInput v-model="email" name="email" type="email" icon="mail-line"
          :placeholder="t('NewsletterSection.input-placeholder')" class="mb-5" required />

        <div class="flex justify-end">
          <PrimaryButton :label="t('NewsletterSection.subscribe')" :dark="false" :white-border="true" />
        </div>
      </form>
      <div v-else class="rounded-lg bg-green/40 py-1 px-4">
        {{ $t('NewsletterSection.success') }}
      </div>
    </div> -->
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n()

const newsletter = ref()

const email = ref(null)

const errors = ref([])
const sent = ref(false)

const send = async () => {
  const { data } = await useFetch('/api/newsletter/register', {
    method: 'post',
    body: { email: email.value }
  })

  errors.value = data.value?.error || []

  if (errors.value.length > 0) {
    newsletter.value.scrollIntoView()
  } else {
    sent.value = true
  }
}
</script>

<style scoped>
#newsletter {
  scroll-margin-top: 80px;
}
</style>
